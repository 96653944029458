.container.info {
  --accent-color: var(--color-highlight-blue);
}

.container.warning {
  --accent-color: var(--color-highlight-orange);
}

.container.success {
  --accent-color: var(--color-highlight-green);
}

.container.error {
  --accent-color: var(--color-highlight-red-n25);
}

.container {
  background-color: var(--color-gray-light-p50);
  border: 1px solid var(--accent-color);
  border-radius: 4px;
  display: flex;
  margin: 0.5rem 0 1.5rem 0;
  padding: 1rem;
}

.icon {
  color: var(--accent-color);
  font-size: 1.5rem;
}

.content {
  margin-left: 1rem;
}

.title {
  color: var(--color-gray-dark-p12);
  font-size: 1.15rem;
  font-weight: 800;
  margin-bottom: 0.5rem;
}
