h1.heading {
  color: var(--color-gray-dark-p12);
  font-size: 1.75rem;
  font-weight: 800;
}

h2.heading {
  color: var(--color-gray-dark-p12);
  font-size: 1.25rem;
  font-weight: 800;
}

h3.heading {
  color: var(--color-gray-dark-p25);
  font-size: 1.15rem;
  font-weight: 800;
}

.subtitle {
  color: var(--color-gray-dark-p50);
  font-size: 60%;
  font-weight: 600;
  margin-top: -0.15rem;
}

.page_title {
  margin-bottom: 2rem;
}
