.backdrop {
  animation-duration: 0.25s;
  animation-name: backdrop_show;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  left: 0;
  overflow: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}

@keyframes backdrop_show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.container {
  animation-duration: 0.25s;
  animation-name: flyout_show;
  background-color: var(--color-gray-light);
  border-radius: 4px 0 0 4px;
  box-shadow: -2px 0px 20px 0px var(--color-gray-dark-p25);
  margin-bottom: 1rem;
  margin-left: auto;
  margin-top: 1rem;
  min-width: 50rem;
  padding: 1rem;
  max-width: 85rem;
  width: 80%;
}

@keyframes flyout_show {
  from {
    opacity: 0;
    margin-right: -100%;
  }

  to {
    opacity: 1;
    margin-right: 0;
  }
}

.header {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.back_button {
  color: var(--color-gray-dark-p50);
  cursor: pointer;
  font-size: 1.25rem;
  margin-bottom: 2rem;
  margin-right: 1rem;
  padding: 0.5rem;
  transition: color 0.25s;
}

.back_button:hover {
  color: var(--color-gray-dark);
}

.content {
  margin-right: -1rem;
  max-height: 95%;
  padding-right: 1rem;
  overflow-y: scroll;
}

@media (max-width: 1000px) {
  .container {
    border-radius: 4px 4px 0 0;
    box-shadow: 0px -2px 20px 0px var(--color-gray-dark-p25);
    min-width: 0;
    margin: auto;
    margin-bottom: 0;
    margin-top: 1rem;
    padding-bottom: 2rem;
    width: 85%;
  }

  @keyframes flyout_show {
    from {
      opacity: 0;
      margin-top: 225%;
    }

    to {
      opacity: 1;
      margin-top: 5%;
    }
  }
}
