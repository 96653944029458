.card {
  background-color: var(--color-gray-light-p50);
  border: 1px solid var(--color-gray-dark-p75);
  border-radius: 4px;
  margin: 0.5rem 0 1.5rem 0;
  padding: 1rem;
}

.title {
  color: var(--color-gray-dark-p12);
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 0.5rem;
}

.collapsible_card .title {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  transition: margin-bottom 0.15s ease-in-out;
}

.collapsible_card .icon_container {
  margin-left: auto;
}

.collapsible_card .icon {
  transition: transform 0.15s ease-in-out;
}

.collapsible_card.expanded .icon {
  transform: rotate(90deg);
}
